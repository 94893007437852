<template>
    <div>
        <van-nav-bar class="navbar" title="房源列表" fixed left-arrow @click-left="leftReturn"/>
        <div class="head">
            <div class="headd">
                <div class="cheeep"><img class="cheeet" src="../../../assets/images/3.png"></div>
                <div class="cheeec">大连</div>
                <div class="search">
                    <div class="search-icon"></div>
                    <input type="text" placeholder="搜索小区，商圈" style="margin-left:-12px">
                </div>
            </div>
            <div class="types">
                <div class="typef" @click="showOrHidden" id="downList">{{type}}</div>
                <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
                <div class="content-divide-sa">|</div>
                <div class="order" @click="showOrder" id="downOrderList">{{order}}</div>
                <div :class="isOrderShow?'small-icon-rotate':'small-icon'"></div>
                <div class="content-divide-ac">|</div>
                <div class="sif" @click="showIf" id="downIfList">{{sif}}</div>
                <div :class="isIfShow?'small-icon-rotate':'small-icon'"></div>
            </div>
        </div>
        <!--商圈下拉列表-->
        <div class="selectList" v-show="isShow">
            <div class="select" :class="{selectActive:index===number}" @click="changeType(index)"
                 v-for="(item,index) in typeList" :key="index">
                {{item.reviewType}}
            </div>
        </div>
        <!--综合排序下拉列表-->
        <div class="selectList" v-show="isOrderShow">
            <div class="select" :class="{selectActive:index===number}" @click="changeOrderType(index)"
                 v-for="(itemOrder,index) in typeOrderList" :key="index">
                {{itemOrder.reviewOrderType}}
            </div>
        </div>
        <div>
            <van-popup class="screen" v-model="maskShow" position="right">

                <div class="screety">类型</div>
                <div class="sty">
                    <span class="stye"  @click="changenos() " :class="{'bgcolor':-1==spanIndex}">不限</span>
                    <span class="stye" v-for="( item,index) in atypList" :key="index" @click="changeSpan(index)"
                          :class="{'bgcolor':index==spanIndex}">{{ item.dictionaryTitle}}</span>
                </div>
                <div class="screety">价格</div>
                <div class="sty">
                    <span class="stye" @click="pricechangenos()" :class="{'bgcolor':-1==pricecSIdex}">不限</span>
                    <span class="styeprice" v-for="(item,index) in priceList" :key="index"
                          @click="pricechangeSpan(index)" :class="{'bgcolor':index==pricecSIdex}">{{item.dictionaryTitle}}</span>
                </div>
                <div class="screety">户型</div>
                <div class="sty">
                    <span class="stye" @click="housechangenos()" :class="{'bgcolor':-1==houseSIdex}">不限</span>
                    <span class="styeprice" v-for="(item,index) in souseList" :key="index"
                          @click="housechangeSpan(index)" :class="{'bgcolor':index==houseSIdex}">{{item.dictionaryTitle}}</span>
                </div>
               <!-- <div class="screety">期限</div>
                <div class="sty">
                    <span class="stye" v-for="(item,index) in dateList" :key="index" @click="datechangeSpan(index)"
                          :class="{'bgcolor':index==dateSIdex}">{{item}}</span>
                </div> -->
                <div class="screety">房源特色</div>
                <div class="sty">
                    <span class="stye" @click="charachangenos()" :class="{'bgcolor':charaSnosIdex==-1}">不限</span>
                    <span class="styeprice" v-for="(item,index) in charaList" :key="index" @click="charachangeSpan(index)"
                          :class="{'bgcolor':charaSIdex.indexOf(index)>-1}">{{item.dictionaryTitle}}</span>
                </div>
                <div class="scrsty">
                    <span @click="cleanSpan()"><img class="clean-icon" src="../../../assets/images/4.png"></span> <span
                        class="clean_button" @click="cleanSpan()">清空</span>
                    <span class="screen_button" @click="screenSpan()">筛选</span>
                </div>
            </van-popup>
        </div>
        <!--遮罩层-->
        <div id="light" class="white_content">
        </div>
        <div id="fade" class="black_overlay">
        </div>
        <div id="fadeorder" class="black_order_overlay">
        </div>
        <div class="reviews">

            <!--寻找房源列表-->
            <div class="review">
                <div class="details" v-for="(item,index) in houseList" :key="index"  @click="jumpHouseList(index)">
                    <div class="info">
                        <img class="partsdt" :src=item.roomPhotoPath>
                    </div>

                    <div class="infos">
                        <div class="position">{{item.roombusinessCircleName}}</div>
                        <div class="keyAddress">
                            {{item.roomFuzzyAddress}}
                            <span class="content-divide-dos">|</span>
                            {{item.roomArea}}m²
                            <span class="content-divide-dos">|</span>
                            {{item.roomFloor}}层
                        </div>
                        <div class="mam">
                            <div class="money">{{item.roomRent}}元</div>
                            <div class="month">/月</div>
                        </div>
                        <div class="zu" >
                            <span class="state" v-show="item.houseType == 0">整租</span>
                            <span class="state" v-show="item.houseType == 1">合租</span>
                            <span class="state" v-show="item.houseType == 2">集寓</span>
                            <span class="state" v-show="item.houseType == 3">独家</span>
                            <span v-if="item.roomCharacteristicList">
                            <span class="statp" v-if="item.roomCharacteristicList.length>= 1">{{item.roomCharacteristicList[0].roomCharacteristicName}}</span>
                            <span class="statp" v-if="item.roomCharacteristicList.length>= 2">{{item.roomCharacteristicList[1].roomCharacteristicName}}</span>
                            <span class="statp" v-if="item.roomCharacteristicList.length== 3">{{item.roomCharacteristicList[2].roomCharacteristicName}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <rxTabbar checked="house"></rxTabbar>

    </div>
</template>

<script>
    import {
        NavBar, Search, DropdownMenu,
        DropdownItem, Popup, Tag,Tabbar, TabbarItem
    } from 'vant';
    import {browseRoomList, queryBaseData,homePageRoomInit} from "../../../getData/getData";
    import {responseUtil} from "../../../libs/rongxunUtil";
    import rxTabbar from '../../../components/rongxun/rx-tabbar/tabbar'

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Search.name]: Search,
            [Popup.name]: Popup,
            [Tag.name]: Tag,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [TabbarItem .name]: TabbarItem ,
            [Tabbar.name]: Tabbar,
            rxTabbar
        },
        name: "seekHouseCollection",
        data() {
            return {
                active:1,
                spanIndex: -1,
                pricecSIdex: -1,
                houseSIdex: -1,
                dateSIdex: 0,
                charaSnosIdex: -1,
                charaSIdex: [],
                maskShow: false,
                number: 0,
                type: '商圈',
                order: '综合排序',
                sif: '条件筛选',
                typeList: [
                    {reviewType: '商圈'}, {reviewType: '天通苑南'}, {reviewType: '天信花园'},
                ],
                atypList: ['不限', '合租', '整租', '集寓'],
                priceList: ['不限', '1k-2K', '2k-3K', '2k-3K', '3k以上'],
                souseList: ['不限', '4室2厅1卫', '1室1厅1卫', '2室2厅', '2室2厅'],
                dateList: ['不限', '4-6个月', '6个月+'],
                charaList: ['不限', '空间大', '独卫', '独卫'],
                typeOrderList: [
                    {reviewOrderType: '默认排序'}, {reviewOrderType: '价格从低到高'}, {reviewOrderType: '价格从高到低'}, {reviewOrderType: '距离从近到远'},
                ],
                isShow: false,
                isOrderShow: false,
                isIfShow: false,
                isWait: false,
                isAll: true,
                list: {
                    content: ''
                },
                //寻房列表
                houseList: [],
                //模拟天通苑南的列表
                list1: [
                    {
                        url: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1936229199,1233983350&fm=26&gp=0.jpg',
                        reviewType: '天通苑南',
                        position: '天通苑南 天通苑西区 朝南主卧',
                        address: '沙河口',
                        area: '123',
                        floor: '6层',
                        state: 1,
                        statp: ['loft', '飘窗'],
                        money: 809,
                    },
                    {
                        url: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1936229199,1233983350&fm=26&gp=0.jpg',
                        reviewType: '天通苑南',
                        position: '天通苑南 天通苑西区 朝南主卧',
                        address: '沙河口',
                        area: '123',
                        floor: '6层',
                        state: 1,
                        statp: ['loft', '飘窗'],
                        money: 809,
                    },
                ],
                //模拟天信花园的列表
                list2: [
                    {
                        url: 'http://a3.att.hudong.com/14/75/01300000164186121366756803686.jpg',
                        reviewType: '天信花园',
                        position: '天信花园 带花园公寓',
                        address: '孝陵卫',
                        area: '123',
                        floor: '6层',
                        money: 1200,
                        state: 0,
                        statp: ['花园', '大阳台', '浴缸'],
                    },
                    {
                        url: 'http://a3.att.hudong.com/14/75/01300000164186121366756803686.jpg',
                        reviewType: '天信花园',
                        position: '天信花园 带花园公寓',
                        address: '孝陵卫',
                        area: '123',
                        floor: '6层',
                        money: 1200,
                        state: 0,
                        statp: ['花园', '大阳台', '浴缸'],
                    },],
                //空的集合用来转换数据
                list0: [
                    {
                        url: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1936229199,1233983350&fm=26&gp=0.jpg',
                        reviewType: '天通苑南',
                        position: '天通苑南 天通苑西区 朝南主卧',
                        address: '沙河口',
                        area: '123',
                        floor: '6层',
                        state: 1,
                        statp: ['loft', '飘窗'],
                        money: 809,
                    },
                    {
                        url: 'http://a3.att.hudong.com/14/75/01300000164186121366756803686.jpg',
                        reviewType: '天信花园',
                        position: '天信花园 带花园公寓',
                        address: '孝陵卫',
                        area: '123',
                        floor: '6层',
                        money: 1200,
                        state: 0,
                        statp: ['花园', '大阳台', '浴缸'],
                    },
                    {
                        url: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1936229199,1233983350&fm=26&gp=0.jpg',
                        reviewType: '天通苑南',
                        position: '天通苑南 天通苑西区 朝南主卧',
                        address: '沙河口',
                        area: '123',
                        floor: '6层',
                        state: 1,
                        statp: ['loft', '飘窗'],
                        money: 809,
                    },
                    {
                        url: 'http://a3.att.hudong.com/14/75/01300000164186121366756803686.jpg',
                        reviewType: '天信花园',
                        position: '天信花园 带花园公寓',
                        address: '孝陵卫',
                        area: '123',
                        floor: '6层',
                        money: 1200,
                        state: 0,
                        statp: ['花园', '大阳台', '浴缸'],
                    },
                ]
            }
        },
        mounted: function () {
            // 初始化
            let initData = {}
            initData.roomType = '1'
            initData.currentPage = '1'
            initData.numberPage = '5'
            this.initData(initData)
            this.initQueryHouseData();
        },
        methods: {
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            // 页面初始化
            initData: function (initData) {
                var that = this
                // initData.city_id=15     //赵
                browseRoomList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                         console.log(response.data.data.data)

                        that.houseList = response.data.data.data
                        // console.log(that.houseList[0].roomCharacteristicList.length)
                    })
                })
            },
            // 页面初始化
            initQueryHouseData: function () {
                var that = this
                let initQueryHouseData = {}
                initQueryHouseData.dbName = ["priceRange","houseType","housingSource"]
                initQueryHouseData.fdName = ["HOUSERETYPEMAP"]
                queryBaseData(initQueryHouseData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        // console.log(response.data.data)
                        that.atypList = response.data.data.HOUSERETYPEMAP
                        that.priceList = response.data.data.priceRange
                        that.souseList = response.data.data.houseType
                        that.charaList = response.data.data.housingSource
                   //     console.log( that.charaList)
                        // console.log(that.houseList[0].roomCharacteristicList.length)
                    })
                })
            },

            //商圈 下拉列表
            showOrHidden: function () {
                this.isShow = true;
                var sp = document.getElementById("downList");
                //点击后标头变换颜色
                document.getElementById('downList').style.color = '#FF5D3B';
                //自定义显示遮罩
                document.getElementById('light').style.display = 'block';
                document.getElementById('fade').style.display = 'block'
                if (sp) {
                    document.addEventListener('click', e => {
                        if (!sp.contains(e.target)) {
                            this.isShow = false;
                            document.getElementById('downList').style.color = 'black';
                            //关闭遮罩
                            document.getElementById('light').style.display = 'none';
                            document.getElementById('fade').style.display = 'none'
                        }
                    })
                }
            },
            //综合排序下拉列表
            showOrder: function () {
                this.isOrderShow = true;
                var sp = document.getElementById("downOrderList");
                document.getElementById('downOrderList').style.color = '#FF5D3B';
                //自定义显示遮罩
                document.getElementById('light').style.display = 'block';
                document.getElementById('fadeorder').style.display = 'block'
                if (sp) {
                    document.addEventListener('click', e => {
                        if (!sp.contains(e.target)) {
                            this.isOrderShow = false;
                            document.getElementById('downOrderList').style.color = 'black';
                            //关闭遮罩
                            document.getElementById('light').style.display = 'none';
                            document.getElementById('fadeorder').style.display = 'none'
                        }
                    })
                }
            },
            //条件筛选下拉列表
            showIf: function () {
                this.maskShow = true;
            },
            changeType: function (index) {
                this.isShow = false;
                this.number = index;
                //console.log(index);
                this.type = this.typeList[index].reviewType;
                switch (index) {
                    case 0:
                        this.houseList = this.list0;
                        break;
                    case 1:
                        this.houseList = this.list1;
                        break;
                    case 2:
                        this.houseList = this.list2;
                        break;
                    case 3:
                        this.houseList = this.list3;
                        break;
                }
            },

            changeOrderType: function (index) {
                this.isOrderShow = false;
                this.number = index;
                //console.log(index);
                this.order = this.typeOrderList[index].reviewOrderType;
                switch (index) {
                    case 0:
                        this.houseList = this.list0;
                        break;
                    case 1:
                        this.houseList.sort((a, b) => a['money'] > b['money'] ? 1 : -1);
                        break;
                    case 2:
                        this.houseList.sort((a, b) => b['money'] > a['money'] ? 1 : -1);
                        break;
                    case 3:
                        this.houseList = this.list0;
                        break;
                }
            },
            changenos: function () {
                this.spanIndex = -1;
            },
            changeSpan: function (index) {
                this.spanIndex = index;
            },
            pricechangenos: function (index) {
                this.pricecSIdex = -1;
            },
            pricechangeSpan: function (index) {
                this.pricecSIdex = index;
            },
            housechangenos: function () {
                this.houseSIdex = -1;
            },
            housechangeSpan: function (index) {
                this.houseSIdex = index;
            },
            datechangeSpan: function (index) {
                this.dateSIdex = index;
            },
            charachangeSpan: function (index) {
                this.charaSnosIdex =0
                let arrIndex = this.charaSIdex.indexOf(index);
                // console.log(arrIndex);
                if (arrIndex > -1) {
                    this.charaSIdex.splice(arrIndex, 1);
                } else {
                    this.charaSIdex.push(index);
                }
            },
            charachangenos: function () {
                this.charaSnosIdex = -1;
                this.charaSIdex.splice(0, this.charaList.length);
            },

            cleanSpan: function () {
                this.spanIndex = -1;
                this.pricecSIdex = -1;
                this.houseSIdex = -1;
                this.dateSIdex = 0;
                this.charaSIdex.splice(0, this.charaList.length);
                this.charaSnosIdex= -1;

            },
            screenSpan: function () {
                let initData = {}
                initData.currentPage = '1'
                initData.numberPage = '5'
                // 房源类型
                if( this.spanIndex!= -1) {
                  initData.roomType = this.atypList[this.spanIndex].dictionaryValue
                }
                // 价格范围id
                if( this.pricecSIdex!= -1) {
                    initData.priceRange_id = this.priceList[this.pricecSIdex].id
                }
                // 户型id
                if( this.houseSIdex!= -1){
                initData.apartment_id = this.souseList[this.houseSIdex].id
                }
                // 期限
                //     if( this.dateSIdex!= -1) {
                //  initData.numberPage = this.souseList[this.dateSIdex].id
                //     }
                // 房源特色列表

                if (this.charaSnosIdex == 0) {
                    var a;
                    initData.roomFeatureList = [];
                    for (var i = 0; i < this.charaSIdex.length; i++) {
                        a = this.charaSIdex[i];
                        initData.roomFeatureList.push(this.charaList[a].id)
                    }
                 //   console.log(initData.roomFeatureList)
                }
                // console.log("ss="+ initData.roomFeatureList)
                this.initData(initData);
                this.maskShow = false;
            },
              //跳转房源详情
                jumpHouseList: function (index) {
              //  console.log("ss="+ this.houseList[index].room_id)
                this.$router.push({ name: 'houseDetails', params: { room_id: this.houseList[index].room_id }});
                },
        }
    }
</script>

<style scoped>
    .navbar {
        z-index: 10;
        background-color: #FFFFFF;
    }

    .head {
        width: 100%;
        position: fixed;
        z-index: 8;
        overflow: hidden;
        background: #FFFFFF;

    }

    .headd {
        display: flex;
    }

    .search {
        margin: 60px 20px 0 0;
        width: 100%;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }

    .cheeec {
        padding-left: 12px;
        display: flex;
        margin: 70px 0 0 10px;
        width: 15%;
        height: 5px;
        font-size: 15px;
        font-weight: 500;

    }

    .cheeep {
        padding-left: 20px;
        display: flex;
        margin: 70px 0 0 0;
        width: 5px;
        height: 25px;

    }

    .cheeet {

        width: 27px;
        height: 20px;

    }

    .search-icon {
        width: 25px;
        height: 25px;
        margin: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 80%;
        background-position: center;
        background-repeat: no-repeat;
    }

    input {
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        width: 120px;
    }

    input::-webkit-input-placeholder {
        color: #d9d9d9;
    }

    .types {
        width: 100%;
        height: 45px;
        display: flex;
    }

    .typef {
        width: 60px;
        text-align: right;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        margin: 15px 0 0 18px;
    }

    .order {
        width: 80px;
        text-align: right;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        margin: 15px 0 0 10px;
    }

    .sif {
        width: auto;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        margin: 15px 0 0 21px;
    }

    .content-divide-sa {
        font-size: 14px;
        margin: 15px 0 0 35px;
        color: #f4f4f4;
    }

    .content-divide-ac {
        font-size: 14px;
        margin: 15px 0 0 25px;
        color: #f4f4f4;
    }

    .content-divide-dos {
        font-size: 14px;
        margin: 15px 0 0 5px;
        color: #999999;
    }

    .keyAddress {
        font-size: 12px;
        height: auto;
        padding-top: 3px;
        padding-left: 17px;
        color: #7d7b7b;
    }

    .details .infos .zu .state {
        width: 125px;
        height: 50px;
        background-color: rgb(255, 221, 220);
        color: rgb(255, 91, 62);
        font-size: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 1px 2px;
        margin-right: 8px;
    }

    .details .infos .zu .statp {
        width: 125px;
        background-color: #DEEBFB;
        color: #86B3E8;
        font-size: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 1px 2px;
        margin-right: 8px;
    }

    .screety {
        font-size: 13px;
        font-weight: bold;
        margin-left: 20px;
        margin-top: 25px;
    }

    .sty {
        margin-left: 30px;
    }

    .stye {
        white-space: nowrap; /*强制span不换行*/
        display: inline-block; /*将span当做块级元素对待*/
        overflow: hidden; /*超出宽度部分隐藏*/
        text-overflow: ellipsis; /*超出部分以点号代替*/
        line-height: 0.9; /*数字与之前的文字对齐*/
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 7px 12px;
        margin-right: 6px;
    }

    .styeprice {
        white-space: nowrap; /*强制span不换行*/
        display: inline-block; /*将span当做块级元素对待*/
        overflow: hidden; /*超出宽度部分隐藏*/
        text-overflow: ellipsis; /*超出部分以点号代替*/
        line-height: 0.9; /*数字与之前的文字对齐*/
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 7px 12px;
        margin-right: 16px;
    }

    .styehouse {
        width: 125px;
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 5px 12px;
    }

    .bgcolor {
        background: rgb(255, 221, 220);
        color: rgb(255, 91, 62);
        font-size: 12px;
        text-align: center;
        border-radius: 5px;
    }

    .small-icon {
        width: 8px;
        height: 8px;
        margin: 20px 0 0 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .small-icon-rotate {
        width: 8px;
        height: 8px;
        margin: 20px 0 0 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform: rotate(180deg);
    }

    .selectList {
        position: fixed;
        z-index: 9;
        width: 100%;
        height: auto;
        top: 140px;
        padding: 5px 0 14px 0;
        background-color: white;
    }

    .select {
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }

    .selectActive {
        color: #fe5e3a;
    }

    .reviews {
        width: 100%;
        overflow: hidden;
        height: auto;
        position: relative;
        top: 140px;
        background-color: #FFFFFF;
    }

    .review {
        margin: 0 15px 15px 15px;
        height: auto;
        background-color: #FFFFFF;
        border-radius: 8px;
        overflow: hidden;
    }

    .position {
        margin: 15px 15px 0 16px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        text-align: left;
        color: black;
        overflow: hidden; /*超出部分隐藏*/
        white-space: nowrap; /*不换行*/
    }

    .details {
        width: 100%;
        height: auto;
        display: flex;
    }

    .details .info {
        width: 110px;
        height: auto;
        text-align: center;
        margin: 12px 8px 0 8px;

    }

    /*控制图片*/
    .partsdt {
        width: 130px;
        height: 95px;
        fit: contain;
        border-radius: 10px;
    }

    .details .infos {
        padding-top: 0px;
        width: auto;
        height: auto;
        margin: 0 8px 0 8px;
    }

    .details .info .order {
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;

    }

    .details .infos .mam .money {
        width: 50px;
        padding-top: 7px;
        padding-left: 15px;
        font-size: 13px;
        color: #fe5e3a;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
        -webkit-transform: scale(1, 1.5);
        float: left;

    }

    .details .infos .mam .month {
        font-weight: 400;
        color: #fe5e3a;
        -webkit-transform: scale(1.1, 1.5);
        padding-top: 12px;
        font-size: 8px;
    }

    .details .infos .zu {
        padding-left: 16px;
        transform: translateY(-20%);
    }

    .screen {
        width: 67%;
        height: 100%;
        padding-top: 80px;

    }

    .scrsty {
        margin-top: 8px;
        padding-top: 70px;
        height: 80px;
        position: relative;
    }

    /*垃圾桶*/
    .clean-icon {
        height: 20px;
        padding: 0 5px 0 23px;
        width: 16px;
    }

    /*清空*/
    .clean_button {
        position: absolute;
        top: 83px;
        font-size: 14px;
        color: rgba(255, 93, 59, 1);
    }

    /*筛选按钮*/
    .screen_button {
        position: absolute;
        left: 150px;
        padding: 11px 31px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 6px;
        color: rgb(255, 255, 255);
        border: none;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    }

    /*遮罩*/
    .black_overlay {
        display: none;
        position: absolute;
        top: 38%;
        left: 0%;
        width: 100%;
        height: 62%;
        background-color: black;
        z-index: 1001;
        -moz-opacity: 0.8;
        opacity: .80;
        filter: alpha(opacity=88);
    }

    /*遮罩*/
    .black_order_overlay {
        display: none;
        position: absolute;
        top: 45%;
        left: 0%;
        width: 100%;
        height: 55%;
        background-color: black;
        z-index: 1001;
        -moz-opacity: 0.8;
        opacity: .80;
        filter: alpha(opacity=88);
    }

    .white_content {
        display: none;
        position: absolute;
        width: 0%;
        height: 0%;
        background-color: white;
        z-index: 1002;
        overflow: auto;
    }
</style>
